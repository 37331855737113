<template>
  <div>
    <div class="header">
      <div class="header_top">
        当前位置：
        <span class="center">企业管理</span>
        <img src="../../../assets/right.png"
             alt />
        <span class="center">企业区域</span>
      </div>
    </div>
    <div style="display: flex; align-items: center;justify-content: space-between; margin:10px 0;">
      <div style="display: flex; align-items: center">
        <span class="seach_text">用工企业名称：</span>

        <el-select remote
                   reserve-keyword
                   :remote-method="query"
                   @change="getCity"
                   v-model="formInline.workEnterpriseCode"
                   clearable
                   filterable
                   placeholder="请输入企业名称"
                   size="mini"
                   style="margin-right:10px">
          <el-option v-for="item in eList"
                     :key="item.code"
                     :label="item.name"
                     :value="item.code"></el-option>
        </el-select>

        <span class="seach_text">企业状态：</span>
        <el-select size="mini"
                   v-model="formInline.enable"
                   placeholder="请选择"
                   clearable
                   style="margin-right: 10px">
          <el-option :label="item.label"
                     :value="item.value"
                     v-for="(item, i) in stateSelect"
                     :key="i"></el-option>
        </el-select>
        <span class="seach_text">企业区域：</span>
        <el-select v-model="formInline.city"
                   filterable
                   size="mini"
                   @change="containChange"
                   :placeholder="'请输入企业区域'">
          <el-option-group v-for="group in areaSelectList"
                           :key="group.provinceName"
                           :label="group.provinceName">
            <span style="color:red"
                  slot="label">{{group.provinceName}}</span>
            <el-option v-for="item in group.citys"
                       :key="item.city"
                       :label="item.cityName"
                       :value="item.city"></el-option>
          </el-option-group>
        </el-select>
        <el-button size="small"
                   style="margin:0 10px;border-radius: 5px;"
                   type="primary"
                   @click="seach">查询</el-button>
      </div>
      <div>
        <el-button size="small"
                   style="margin:0 10px;border-radius: 5px;"
                   type="primary"
                   @click="addEnterpriseArea">新增企业区域</el-button>
      </div>
    </div>

    <el-table :data="list"
              style="width: 100%">
      <el-table-column label="企业名称 "
                       width="280"
                       show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.work_enterprise.name }}</template>
      </el-table-column>

      <el-table-column label="企业状态"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="color: #333333"
                v-if="scope.row.work_enterprise.enable">启用</span>
          <span style="color: #c2c2c2"
                v-if="!scope.row.work_enterprise.enable">禁用</span>
        </template>
      </el-table-column>
      <el-table-column label="企业区域"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.provinceName}}-{{scope.row.cityName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <!-- <div class="shuxian">
            <span class="clickColor" @click="goEdit(scope.row)">编辑</span>
            <el-divider direction="vertical"></el-divider>
          </div>-->

          <div class="shuxian">
            <el-popover placement="top"
                        width="400"
                        trigger="manual"
                        :value="visible"
                        :ref="'popover-' + scope.row.id">
              <div class="del-pop">
                <img src="../../../assets/del.png"
                     alt />
              </div>
              <div class="del-title">删除企业区域信息</div>
              <div class="del-sumtitle">确认删除该条企业区域信息？</div>
              <div class="del-btn">
                <el-button type="primary"
                           @click="del(scope.row)"
                           plain>确认</el-button>
                <el-button type="info"
                           @click="doCancel(scope.row.id)"
                           plain>取消</el-button>
              </div>
              <el-button slot="reference"
                         style="color:red"
                         type="text"
                         @click="pOpen(scope.row.id)">删除</el-button>
            </el-popover>
            <el-divider direction="vertical"></el-divider>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor"
            style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination background
                     :current-page.sync="pageNumber"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-size="pageSize"
                     prev-text="上一页"
                     next-text="下一页"
                     layout="prev,pager, next,jumper"
                     :total="total"></el-pagination>
    </div>
    <addDialog :dialogVisible.sync="addDialogVisible"
               :code.sync="code"
               :title.sync="title"
               @func="addClose()"></addDialog>
  </div>
</template>

<script>
import { api } from '/src/api/base';

// 企业列表
export const workEnterpriseList = api()('work_enterprise.list.json');
// 区域列表
export const workEnterpriseAreaList = api()('work_enterprise_area.list.json');
// 删除区域
export const workEnterpriseAreaDel = api()('work_enterprise_area.del.json');
// s市区接口
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json');


export default {
  components: {
    addDialog: () => import('./add'),
  },
  data () {
    return {
      visible: false,
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      code: '',
      formInline: {
        workEnterpriseCode: '',
        enable: '',
        city: '',
      },
      list: [],
      eList: [],
      enable: '',
      addDialogVisible: false,
      title: '',
      // "0"：未认证 "1"：打款中  "2"：打款成功  "3"：认证成功 "4"认证失败
      stateSelect: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '启用',
          value: '1',
        },
        {
          label: '禁用',
          value: '0',
        },
      ],

      areaSelectList: [{
        label: '全部',
        value: '',
      }],
    };
  },
  computed: {
    classVal: function () {
      return {
        'font-color-warning': true,
      };
    },
  },
  created () {
    this.loadData();
    this.enterpriseList()
  },
  methods: {
    pOpen (id) {
      this.$refs['popover-' + id].doShow();
    },
    doCancel (id) {
      this.pClose(id);
    },
    pClose (id) {
      this.$refs['popover-' + id].doClose();
    },
    keywords () {
      this.pageNumber = 1;
      this.enterpriseList();
    },
    addEnterpriseArea () {
      this.addDialogVisible = true
      this.title = '新建企业区域'
      this.code = ''
    },
    goEdit (row) {
      this.addDialogVisible = true
      this.title = '编辑企业区域'
      this.code = row.code
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData () {
      const params = {
        ...this.formInline,
        workEnterpriseCode: this.formInline.workEnterpriseCode,
        enable: this.formInline.enable,
        city: this.formInline.city.toString(),
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,

      };

      workEnterpriseAreaList(params).then(res => {
        this.list = res.list
        this.total = res.total
      });

    },
    del (obj) {
      workEnterpriseAreaDel({ code: obj.code }).then(() => {
        this.$message.success('内容成功删除！');
        this.doCancel(obj.id);
        this.loadData();
      });

    },
    addClose () {
      this.loadData();
    },
    query (val) {
      console.log(val);
      this.pageNumber = 1;
      this.enterpriseList(val);
    },
    containChange (val) {
      console.log(val, 222);
    },

    getCity (val) {
      if (val) {
        const params = {
          workEnterpriseCode: val
        };
        workEnterpriseAreaContainArea(params).then(res => {
          this.areaSelectList = res
          this.formInline.city = ''
        });
      }
      else {
        this.areaSelectList = []
        this.formInline.city = ''
      }
    },
    seach () {
      this.pageNumber = 1;
      this.loadData();
    },
    enterpriseList (val) {
      const params = {
        keywords: val,
        pageNumber: 1,
        pageSize: 1000
      };
      workEnterpriseList(params).then(res => {
        this.eList = res.list
      });
    }


  },
};
</script>
<style>
.el-select-group__title {
  color: black;
  font-size: 15px;
  font-weight: 700;
  margin-left: -10px;
}
</style>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  border: none;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;
  .header_top {
    display: flex;
    align-items: center;
    img:nth-child(1) {
      width: 20px;
      height: 17px;
    }
    img:nth-child(2) {
      width: 9px;
      height: 10px;
    }
    span {
      margin: 0 10px;
      font-size: 16px;
      color: #606060;
    }
  }
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.el-form-item {
  margin-top: 12px !important;
  margin-bottom: 10px;
}
.table_border {
  margin: 20px 0;
  .table_top {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background: #4977fc;
    color: #fff;
    font-size: 14px;
  }
  .table_demo {
    padding: 20px 0;
    font-size: 10px;
  }
}
.del-pop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.del-title {
  font-size: 17px;
  text-align: center;
  color: #333333;
  margin: 15px 0;
}
.del-sumtitle {
  font-size: 13px;
  text-align: center;
  color: #a9a9a9;
  margin: 0 0 5px 0;
}
</style>
